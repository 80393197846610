import React, { useEffect } from "react";
import {
  InfoSec,
  InfoRow,
  InfoColumn,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  Form,
  FormInput,
  SubSubtitle,
} from "./InfoSection.elements";
import { Container, Button } from "../../globalStyles";
import { Link } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const InfoSection = ({
  primary,
  lightBg,
  topLine,
  lightTopLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  start,
}) => {
  const [email, setEmail] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await fetch(
        "https://hooks.slack.com/services/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",
        {
          body: JSON.stringify({ text: email }),
          headers: {},
          method: "POST",
        }
      );
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    setIsValid(validateEmail(email));
  }, [email]);

  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                {/* <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine> */}
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>
                {/* <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: 1
                  }}
                >
                  <input placeholder="Email..." style={{ flexGrow: 1 }} />
                  <Button>Join Waitlist</Button>
                </div> */}
                {success ? (
                  <>
                    <SubSubtitle lightTextDesc={lightTextDesc}>
                      Thanks for joining our waitlist!
                    </SubSubtitle>
                  </>
                ) : (
                  <>
                    <SubSubtitle lightTextDesc={lightTextDesc}>
                      Join our waitlist to get added to the early access queue
                    </SubSubtitle>
                    <Form>
                      <FormInput
                        name="email"
                        type="email"
                        placeholder="Enter your email ..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Button
                        fontBig
                        onClick={() => {
                          if (!success && !loading) {
                            setSuccess(true);
                            setEmail("");
                            handleSubmit();
                          }
                        }}
                        disabled={!isValid}
                      >
                        {loading ? (
                          <TailSpin height="20" width="20" />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </Form>
                  </>
                )}
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <Img
                  src={require("../../images/two-peas.svg")}
                  alt="product-pic"
                />
                {/* <Img src={img} alt={alt} /> */}
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  );
};

export default InfoSection;
